/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  DialogActions,
  Checkbox,
  Button,
  Avatar
} from '@mui/material';
import trackUse from '@utils/trackUse';
import { logPostHogEvent } from '@utils/index';

import { FilterChipConfigType } from '../../utils';

const CategoryChip = ({
  categoryFilters,
  setCategoryFilters,
  categoryOptions
}: {
  categoryFilters: string[];
  setCategoryFilters: (value: string[]) => void;
  categoryOptions: FilterChipConfigType;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const renderModal = () => {
    return (
      <Dialog
        sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 535 } }}
        maxWidth="xs"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <DialogTitle>Filter by Category</DialogTitle>
        <DialogContent dividers>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1 // Add spacing between checkboxes
            }}
          >
            {categoryOptions.map((filter) => (
              <FormControlLabel
                key={filter.label}
                control={
                  <Checkbox
                    checked={filter.selected}
                    onChange={() => {
                      logPostHogEvent('filter-chip', {
                        value: `${filter.label} - ${
                          filter.selected ? 'DESELECT' : 'SELECT'
                        }`,
                        type: 'ACTION'
                      });
                      trackUse({
                        item: `filter-chip`,
                        value: `${filter.label} - ${
                          filter.selected ? 'DESELECT' : 'SELECT'
                        }`,
                        type: 'ACTION'
                      });
                      filter.onClick();
                    }}
                    inputProps={{ 'aria-label': filter.label }}
                  />
                }
                label={filter.label}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '16px'
          }}
        >
          <Button
            onClick={() => {
              setCategoryFilters([]);
              setModalOpen(false);
            }}
          >
            Clear All
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setModalOpen(false);
            }}
            color="primary"
            variant="outlined"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  return (
    <>
      {renderModal()}
      <Chip
        label="Category"
        onClick={() => {
          setModalOpen(true);
        }}
        sx={{
          marginRight: '6px'
        }}
        variant={categoryFilters?.length > 0 ? 'filled' : 'outlined'}
        avatar={
          categoryFilters.length === 0 ? undefined : (
            <Avatar>{categoryFilters.length}</Avatar>
          )
        }
        onDelete={
          categoryFilters?.length === 0
            ? undefined
            : () => {
                setCategoryFilters([]);
              }
        }
      />
    </>
  );
};

export default CategoryChip;
