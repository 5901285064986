/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { Box, Typography, Link, Divider } from '@mui/material';
import { useTheme } from '@mui/system';
import config from '@configFile';
import { useSignUpModal } from '@hooks/useLoginModal';
import { getCurrentDealEventStrObj } from '@utils/index';

import { useGetUserData } from '@hooks/useGetUserData';
import MainFilterChip from './components/MainFilterChip';
import { FilterFunctionsType, FilterChipConfigType } from './utils';
import CategoryChip from './components/CategoryChip';
import PriceChip from './components/PriceChip';
import PercentageChip from './components/PercentageChip';

const FilterChips = ({
  funcs: {
    trending,
    setTrending,
    primeDay,
    setPrimeDay,
    onlyCoupons,
    setOnlyCoupons,
    onlySubscribeSave,
    setOnlySubscribeSave,
    onlyShowNew,
    setOnlyShowNew,
    setCategoryFilters,
    categoryFilters,
    onlyFavorites,
    setOnlyFavorites,
    onlySponsored,
    setOnlySponsored,
    underPrice,
    setUnderPrice,
    overPrice,
    setOverPrice,
    percentOff,
    setPercentOff,
    resetAll
  }
}: {
  funcs: FilterFunctionsType;
}) => {
  const { data: user, isLoading } = useGetUserData();
  const { showNonLoggedInModal } = useSignUpModal();
  const isAdmin = !!user?.isAdmin;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  // trigger a render after everything has finished loading
  // this is to attempt to fix the chip background bug
  useEffect(() => {
    if (!isLoading) {
      setOnlyFavorites(!!onlyFavorites);
    }
  }, [isLoading]);

  let filterChipConfig: FilterChipConfigType = [];

  const eventObj = getCurrentDealEventStrObj();

  if (eventObj && eventObj?.shouldShowDeals) {
    const {
      label,
      overrideChipLabel,
      overrideChipColor,
      overrideChipSelectedColor,
      overrideChipColorDarkMode,
      overrideChipSelectedColorDarkMode
    } = eventObj;

    const chipColor =
      (isDarkMode ? overrideChipColorDarkMode : overrideChipColor) ||
      eventObj.dealHighlightColor;
    const chipSelectedColor =
      (isDarkMode
        ? overrideChipSelectedColorDarkMode
        : overrideChipSelectedColor) ||
      eventObj.chipSelectedColor ||
      '#f50095';

    filterChipConfig.push({
      index: 0,
      label: overrideChipLabel || label,
      value: 'eventDay',
      icon: eventObj?.iconName || 'CelebrationIcon',
      iconColor: chipColor,
      // iconColor: !isEarlySale
      //   ? eventObj.dealHighlightColor
      //   : colors.earlyEventDeal,
      // selectedColor: !isEarlySale ? eventObj.chipSelectedColor : '#f50095',
      selectedColor: chipSelectedColor,

      selected: primeDay,
      onClick: () => setPrimeDay(!primeDay),
      isMultifilter: false
    });
  }

  filterChipConfig = filterChipConfig.concat([
    {
      index: 0,
      label: 'Popular',
      value: 'popular',
      icon: 'InsightsIcon',
      iconColor: '#C25700',
      selected: !!trending,
      onClick: () => setTrending(!trending),
      isMultifilter: false
    },
    {
      index: 1,
      label: 'Coupon',
      value: 'coupon',
      icon: 'LocalOfferIcon',
      iconColor: theme.palette.couponColor,
      selected: !!onlyCoupons,
      onClick: () => setOnlyCoupons(!onlyCoupons),
      isMultifilter: false
    },
    {
      index: 2,
      label: 'Subscribe & Save',
      value: 'subscribeSave',
      icon: 'AutorenewIcon',
      iconColor: '#00796b',
      selected: onlySubscribeSave,
      onClick: () => setOnlySubscribeSave(!onlySubscribeSave),
      isMultifilter: false
    },
    {
      index: 3,
      label: 'New',
      value: 'new',
      icon: 'NewReleasesIcon',
      iconColor: theme.palette.newColor,
      selected: onlyShowNew,
      onClick: () => setOnlyShowNew(!onlyShowNew),
      isMultifilter: false
    }
  ]);

  if (user) {
    filterChipConfig.push({
      index: 4,
      label: 'Favorites',
      value: 'favorites',
      icon: 'HeartIcon',
      iconColor: theme.palette.favoriteColor,
      selected: onlyFavorites,
      onClick: () => setOnlyFavorites(!onlyFavorites),
      isMultifilter: false
    });
  } else {
    filterChipConfig.push({
      index: 4,
      label: 'Favorites',
      value: 'favorites',
      icon: 'HeartIcon',
      iconColor: theme.palette.favoriteColor,
      selected: false,
      onClick: () => {
        showNonLoggedInModal({
          onLoginCBFn: () => {
            setOnlyFavorites(!onlyFavorites);
          },
          modalMessage: 'Please login for us to locate your favorites',
          featureName: 'chip-see-favorites-login-modal'
        });
      },
      isMultifilter: false
    });
  }

  if (isAdmin) {
    filterChipConfig.push({
      index: 5,
      label: 'Sponsored',
      value: 'admin',
      icon: 'PaidIcon',
      iconColor: '#388e3c',
      selected: onlySponsored,
      onClick: () => setOnlySponsored(!onlySponsored),
      isMultifilter: false
    });
  }

  // filterChipConfig.push({
  //   index: 5,
  //   label: 'Under $_',
  //   value: 'under-1',
  //   icon: 'PaidIcon',
  //   iconColor: '#388e3c',
  //   selected: underPrice === 1,
  //   onClick: () => setUnderPrice(underPrice === 1 ? null : 1),
  //   isMultifilter: true
  // });

  const categoryOptions = config.CATEGORY_FILTER.filter(
    (cat) => !cat?.skipDatabase
  ).map((category, index) => ({
    index: index + filterChipConfig.length,
    label: category.shortName,
    value: category.value,
    icon: category.icon,
    iconColor: 'inherit',
    isMultifilter: true,
    selected: categoryFilters.includes(category.value),
    onClick: () => {
      const newCategoryFilters = [...categoryFilters];
      if (newCategoryFilters.includes(category.value)) {
        newCategoryFilters.splice(
          newCategoryFilters.indexOf(category.value),
          1
        );
      } else {
        newCategoryFilters.push(category.value);
      }
      setCategoryFilters(newCategoryFilters);
    }
  }));

  const selectedCountMainFilterCount = React.useMemo(() => {
    let filterCount = 0;
    if (underPrice || overPrice) {
      filterCount += 1;
    }

    if (percentOff) {
      filterCount += 1;
    }

    return (
      filterChipConfig.filter((filter) => filter.selected).length + filterCount
    );
  }, [filterChipConfig, underPrice, overPrice, percentOff]);
  const selectedCategoryCount = categoryOptions.filter(
    (filter) => filter.selected
  ).length;
  const selectedCount = selectedCountMainFilterCount + selectedCategoryCount;
  const selectedString = selectedCount > 0 ? ` (${selectedCount})` : '';

  const scrollContainerStyles = {
    // make this scrollable to the left and right
    overflowX: 'auto',
    display: 'flex',
    // do not show scroll bar
    scrollbarWidth: 'none',
    // Firefox
    msOverflowStyle: 'none',
    // Chrome
    '&::-webkit-scrollbar': {
      display: 'none'
    },
    marginRight: {
      xs: '-17px',
      md: 'initial'
    },
    marginLeft: {
      xs: '-10px',
      md: 'initial'
    }
  };

  return (
    <>
      <Box
        sx={{
          margin: '0px auto 10px auto'
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Typography variant="overline">
            {`Filter by ${selectedString}`}
          </Typography>
          <Box>
            <Box>
              <Link
                variant="overline"
                onClick={() => {
                  resetAll();
                }}
                sx={{
                  cursor: 'pointer',
                  color: isDarkMode ? theme.palette.linkColor : '#392e50',
                  fontWeight: 700,
                  fontSize: '0.75rem'
                }}
                href="#"
              >
                Clear all filters
              </Link>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            ...scrollContainerStyles
          }}
        >
          {(filterChipConfig || []).map((filter) => {
            return <MainFilterChip filter={filter} key={filter.label} />;
          })}
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'flex'
              }
            }}
          >
            <CategoryChip
              categoryFilters={categoryFilters}
              setCategoryFilters={setCategoryFilters}
              categoryOptions={categoryOptions}
            />
          </Box>
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'flex'
              }
            }}
          >
            <PriceChip
              underPrice={underPrice}
              setUnderPrice={setUnderPrice}
              overPrice={overPrice}
              setOverPrice={setOverPrice}
            />
          </Box>
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'flex'
              }
            }}
          >
            <PercentageChip
              percentOff={percentOff}
              setPercentOff={setPercentOff}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          ...scrollContainerStyles,
          display: {
            xs: 'flex',
            sm: 'none'
          },
          marginBottom: '12px'
        }}
      >
        <CategoryChip
          categoryFilters={categoryFilters}
          setCategoryFilters={setCategoryFilters}
          categoryOptions={categoryOptions}
        />
        <PriceChip
          underPrice={underPrice}
          setUnderPrice={setUnderPrice}
          overPrice={overPrice}
          setOverPrice={setOverPrice}
        />
        <PercentageChip percentOff={percentOff} setPercentOff={setPercentOff} />
      </Box>
      <Divider
        sx={{
          display: {
            xs: 'flex',
            sm: 'none'
          },
          marginBottom: '12px'
        }}
      />
    </>
  );
};
export default FilterChips;
