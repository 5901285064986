/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Box, Link, Typography } from '@mui/material';
import { useTheme } from '@mui/system';

interface DatabaseEndMessageProps {
  isFiltered: boolean;
  resetAll: () => void;
}

const DatabaseEndMessage = ({
  isFiltered,
  resetAll
}: DatabaseEndMessageProps) => {
  const theme = useTheme();
  return (
    <Box
      textAlign="center"
      margin="30px 8px"
      flex="auto"
      width="100%"
      sx={{
        margin: {
          xs: '30px 8px',
          md: '45px 8px'
        },
        boxSizing: 'border-box',
        transform: 'translateZ(0)', // Force GPU rendering
        willChange: 'transform' // Hint to browser
      }}
    >
      {isFiltered ? (
        <Typography>
          <strong>
            There are no more results, but you can{' '}
            <Link
              onClick={() => {
                resetAll();
              }}
              sx={{
                color: theme.palette.linkColor,
                cursor: 'pointer'
              }}
            >
              remove your filters
            </Link>{' '}
            to see more deals!
          </strong>
        </Typography>
      ) : (
        <b>Yay! You have seen it all</b>
      )}
    </Box>
  );
};

export default DatabaseEndMessage;
