import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';

interface LoadingPhrasesProps {
  phrases: string[];
  delay?: number; // Optional: Delay in milliseconds for switching phrases
}

const LoadingPhrases: React.FC<LoadingPhrasesProps> = ({
  phrases,
  delay = 2000
}) => {
  const [currentIndex, setCurrentIndex] = useState(
    Math.floor(Math.random() * phrases.length)
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex(() => Math.floor(Math.random() * phrases.length));
    }, delay);

    return () => clearInterval(interval);
  }, [phrases, delay]);

  return (
    <Box
      sx={{
        backgroundColor: 'rgba(0, 0, 0, 0.2)',
        padding: '10px',
        borderRadius: '8px'
      }}
    >
      <Typography variant="body2" component="p">
        {phrases[currentIndex]}
      </Typography>
    </Box>
  );
};

export default LoadingPhrases;
