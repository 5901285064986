import React, { useState } from 'react';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
// import Avatar from '@mui/material/Avatar';
// import { useTheme } from '@mui/system';
import { useTheme } from '@mui/system';
import { Button, useMediaQuery } from '@mui/material';
import { DealPostType } from '@types';
import strategyIds from '@utils/constants/strategyIds';
import ShowIcon from '@components/ShowIcon';

type FrequencyResult = {
  value: string;
  total: number;
};

const StrategyChips = ({
  currentlyShowingDeals,
  strategyFilter,
  setStrategyFilter,
  isLoading
}: {
  currentlyShowingDeals: DealPostType[];
  strategyFilter: string;
  setStrategyFilter: (value: string) => void;
  isLoading: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const getStrategyLabelByValue = (value: string) => {
    const strategy = strategyIds.find((id) => id.value === value);
    return strategy ? strategy.label : value;
  };

  const getStrategyObjByValue = (value: string) => {
    return strategyIds.find((id) => id.value.trim() === value.trim());
  };

  const getStrategyIdFrequency = (data: DealPostType[]): FrequencyResult[] => {
    const frequencyMap: Record<string, number> = {};
    for (let i = 0; i < data.length; i++) {
      const { strategyId } = data[i];
      if (strategyId && strategyId.trim() !== '') {
        const ids = strategyId.split(',').map((id) => id.trim());
        for (let j = 0; j < ids.length; j++) {
          frequencyMap[ids[j]] = (frequencyMap[ids[j]] || 0) + 1;
        }
      }
    }

    // Convert the frequency map to an array of objects
    const frequencyArray: FrequencyResult[] = Object.entries(frequencyMap)
      .map(([value, total]) => ({ value, total }))
      .sort((a, b) => b.total - a.total); // Sort by frequency in descending order

    return frequencyArray;
  };

  const loadingChipStyles = {
    borderRadius: '16px',
    marginRight: '6px',
    display: 'inline-block',
    flexShrink: 0,
    marginBottom: isMobile ? '6px' : '0'
  };

  const isChipSelected = (strategyId: string) => {
    return strategyFilter.includes(strategyId);
  };

  const freq = getStrategyIdFrequency(currentlyShowingDeals);

  const displayStrategyIds = freq
    .filter((f) => strategyIds.find((id) => id.value === f.value))
    .filter((f) => f.total > 3);

  // organize displayStrategyIds by is selected
  displayStrategyIds.sort((a, b) => {
    const aIsSelected = isChipSelected(a.value);
    const bIsSelected = isChipSelected(b.value);
    if (aIsSelected && !bIsSelected) {
      return -1;
    }
    if (!aIsSelected && bIsSelected) {
      return 1;
    }
    return 0;
  });

  if (
    !isLoading &&
    currentlyShowingDeals.length < 3 &&
    displayStrategyIds.length < 5
  ) {
    return null;
  }

  return (
    <Box>
      <Box
        sx={
          isMobile
            ? {
                justifyContent: 'center',
                display: 'flex',
                flexWrap: 'wrap',
                overflow: 'hidden',
                maxHeight: isMobile && !isOpen ? '5.5rem' : '100rem', // Limit to 2.5 rows (~5.5rem) or expand
                position: 'relative',
                transition: 'max-height 0.3s ease-in-out', // Smooth animation
                '&::after': {
                  content: '""', // Required for pseudo-element
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  height: '2rem', // Height of the shadow
                  background: isDarkMode
                    ? `linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%)`
                    : `linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 100%)`,
                  pointerEvents: 'none', // Prevent shadow from blocking clicks
                  opacity: isMobile && !isOpen ? 1 : 0, // Show shadow only when collapsed
                  transition: 'opacity 0.3s ease-in-out' // Smooth fade when expanding/collapsing
                }
              }
            : {
                overflowX: 'auto', // Enable horizontal scrolling
                display: 'flex', // Flex to align the chips
                scrollbarWidth: 'none', // Hide scrollbar in Firefox
                msOverflowStyle: 'none', // Hide scrollbar in IE and Edge
                '&::-webkit-scrollbar': {
                  display: 'none' // Hide scrollbar in Chrome
                },
                whiteSpace: 'nowrap', // Prevent chips from wrapping
                marginRight: {
                  xs: '-17px',
                  md: 'initial'
                },
                marginLeft: {
                  xs: '-10px',
                  md: 'initial'
                },
                marginTop: '8px'
              }
        }
      >
        {isLoading ? (
          // Show multiple loading skeleton chips for better visual effect
          <>
            <Skeleton
              variant="rectangular"
              width="90px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="60px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="75px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="80px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="90px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="100px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="80px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="85px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="95px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="60px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="75px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="80px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="90px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="65px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="95px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="60px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="75px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="70px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="80px"
              height="32px"
              sx={loadingChipStyles}
            />
            <Skeleton
              variant="rectangular"
              width="90px"
              height="32px"
              sx={loadingChipStyles}
            />
          </>
        ) : (
          displayStrategyIds?.map((displayStrategyId) => (
            <Chip
              avatar={
                !isMobile ? (
                  <ShowIcon
                    icon={getStrategyObjByValue(displayStrategyId.value).icon}
                  />
                ) : undefined
              }
              key={displayStrategyId.value}
              label={getStrategyLabelByValue(displayStrategyId.value)}
              sx={
                isMobile
                  ? { marginRight: '6px', marginBottom: '6px' }
                  : { marginRight: '6px' }
              }
              onClick={() => {
                if (isChipSelected(displayStrategyId.value)) {
                  setStrategyFilter(
                    strategyFilter.replace(displayStrategyId.value, '').trim()
                  );
                } else {
                  setStrategyFilter(
                    `${strategyFilter} ${displayStrategyId.value}`.trim()
                  );
                }
              }}
              variant={
                isChipSelected(displayStrategyId.value) ? 'filled' : 'outlined'
              }
              onDelete={
                !isChipSelected(displayStrategyId.value)
                  ? undefined
                  : () => {
                      setStrategyFilter(
                        strategyFilter
                          .replace(displayStrategyId.value, '')
                          .trim()
                      );
                    }
              }
            />
          ))
        )}
      </Box>
      {isMobile && (
        <Button
          onClick={() => setIsOpen(!isOpen)}
          sx={{
            display: 'block',
            margin: '0 auto',
            marginTop: '8px',
            textTransform: 'none'
          }}
          size="small"
          variant="outlined"
        >
          {isOpen ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </Box>
  );
};

export default StrategyChips;
