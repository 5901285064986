import React, { memo, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  Box,
  Typography,
  Button
} from '@mui/material';
import { formatDistance } from 'date-fns';
import { useTheme } from '@mui/system';
import colors from '@utils/colors';
import { CarouselRibbon } from '@components/Ribbon';
import config from '@configFile';
import { DealPostType, DEALS_UPDATE_RECEIPT_DEAL } from '@types';
import CouponChip from '@components/CouponChip';
import FavoriteButton from '@components/FavoriteButton';
import NewTooltip from '@components/NewTooltip';
import { MonetizingLink } from '@components/index';
import {
  parseTitle,
  getPostPrice,
  getProductLink,
  logPostHogEvent,
  getCurrentDealEventStrObj
} from '@utils/index';
import SubscribeSaveChip from '@components/SubscribeSaveChip';
import PromoFixedChip from '@components/PromoFixedChip';
import trackUse from '@utils/trackUse';
import { useGetFavorites } from '@hooks/useFavorites';
import { useGetUserSettings, defaultUISettings } from '@hooks/useUserSettings';
import { useGetUserData } from '@hooks/useGetUserData';
import { dealsAction } from '@actions/index';
import StrategyIds from '@pages/Deals/components/DealCard/components/StrategyIds';
import classes from '../styles';
import DealCardActions from './components/DealCardActions';
import PercentageDiscount from './components/PercentageDiscount';

interface DealCardProps extends DealPostType {
  onlyCoupons: boolean;
  toggleOnlyCoupons: () => void;
  tag?: string;
}

const DealInnerCard = memo((props: DealCardProps) => {
  const {
    title,
    image,
    image240Url,
    postDate,
    ASIN,
    finalPrice,
    ss,
    couponPercentage,
    couponFixed,
    promoFixed,
    tag,
    socialBought
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();
  const { data: userFavorites } = useGetFavorites();
  const { data: userSettings } = useGetUserSettings();
  const { data: userData } = useGetUserData();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const affiliateTag = tag || config.AFFILIATE_TAGS.DATABASE_CONTROL;

  const shouldShowRibbons = useMemo(() => {
    return !!userData && userSettings
      ? !!userSettings.databaseShowRibbons
      : defaultUISettings.databaseShowRibbons;
  }, [userSettings]);

  const isFavorite = useMemo(() => {
    return ASIN && userFavorites?.includes(ASIN);
  }, [ASIN, userFavorites]);

  const link = useMemo(
    () => getProductLink(ASIN, affiliateTag),
    [ASIN, affiliateTag]
  );

  const handleDetailsClick = useCallback(() => {
    dispatch({ type: DEALS_UPDATE_RECEIPT_DEAL, receiptDeal: props });
    dispatch(dealsAction.getDealSummary(ASIN));
    history.push(`/deal/${ASIN}`);
    trackUse({ item: 'details-button-click', value: ASIN, type: 'CLICK' });
    logPostHogEvent('details-button-click', {
      item: 'details-button-click',
      value: ASIN,
      type: 'CLICK'
    });
  }, [dispatch, history, ASIN, props]);

  const getCardChips = useMemo(() => {
    const chips = [];

    if (couponPercentage || couponFixed) {
      chips.push(<CouponChip {...props} key="coupon-chip" />);
    }

    if (ss) {
      chips.push(<SubscribeSaveChip {...props} key="ss-chip" />);
    }

    if (promoFixed) {
      chips.push(<PromoFixedChip {...props} key="promo-chip" />);
    }

    return chips.length > 0 ? (
      <Box sx={classes.chipWrapper}>
        <PercentageDiscount {...props} />
        {chips}
      </Box>
    ) : (
      <PercentageDiscount {...props} />
    );
  }, [
    couponPercentage,
    couponFixed,
    ss,
    promoFixed,
    classes.chipWrapper,
    props
  ]);

  const renderNewTooltip = useMemo(() => {
    return (
      <NewTooltip variant="caption" usePostDate deal={props} label="*New*" />
    );
  }, [props]);

  const shouldDisplayEventDeal = useMemo(() => {
    const currentEventObj = getCurrentDealEventStrObj();
    return (
      currentEventObj &&
      currentEventObj?.shouldShowDeals &&
      (props.listingMode || '')
        .toLowerCase()
        .includes(currentEventObj.value.toLowerCase())
    );
  }, [props.listingMode]);

  const image240UrlWebp = image240Url?.replace('.jpg', '.webp');

  return (
    <>
      <Box>
        <MonetizingLink
          href={link}
          clickType="TEXT"
          tag={affiliateTag}
          item="deal-image-wrapper"
        >
          {shouldShowRibbons &&
            (props.isBestSeller || shouldDisplayEventDeal || isFavorite) && (
              <CarouselRibbon
                dealSummary={props}
                onlyShowImportant
                opacity={1}
                padding="1px 8px"
                fontSize="11px"
              />
            )}
          <ButtonBase sx={classes.imageWrapper}>
            <Box
              sx={{
                // width: 140,
                height: 140,
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '3px',
                paddingLeft: '4px'
              }}
            >
              <Box
                component="img"
                alt={title}
                src={(image240UrlWebp || image)?.replace('http:', 'https:')}
                sx={classes.imageMobile}
              />
            </Box>
          </ButtonBase>
        </MonetizingLink>
        <CardContent sx={classes.cardContent}>
          <MonetizingLink
            href={link}
            clickType="TEXT"
            tag={affiliateTag}
            item="deal-card-content"
            sx={{
              textDecoration: 'none',
              color: isDarkMode ? '#ccc' : colors.jdbPurple,

              '&:hover': {
                color: isDarkMode ? '#fff' : 'black'
              }
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                display: '-webkit-box !important',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '13px !important',
                '@media (min-width: 1700px)': {
                  // larger text for larger screens
                  fontSize: '14px !important'
                }
              }}
            >
              {`${parseTitle(title)}`}
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              component="div"
              sx={{
                display: '-webkit-box !important',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '14px !important',
                color: isDarkMode
                  ? '#eaeaea'
                  : theme.palette.secondaryTextColor,
                fontWeight: '600 !important',

                '@media (max-width: 48rem)': {
                  WebkitLineClamp: 'unset !important'
                }
              }}
            >
              {`${getPostPrice(props, false)}`}
            </Typography>
            <Typography
              sx={{
                ...classes.mobileDate,
                color: theme.palette.secondaryTextColor
              }}
            >
              {renderNewTooltip}
              {`Added ${formatDistance(new Date(postDate), new Date(), {
                addSuffix: true
              })}`}
            </Typography>
            {socialBought ? (
              <Typography
                sx={{
                  ...classes.mobileDate,
                  color: theme.palette.secondaryTextColor
                }}
              >
                {socialBought.replace('bought in', 'bought on Amazon in')}
              </Typography>
            ) : null}
          </MonetizingLink>
          <Box>{getCardChips}</Box>
        </CardContent>
      </Box>
      <StrategyIds {...props} />
      <CardActions sx={classes.actionButtons}>
        <Button
          key="btn-summary"
          sx={{
            backgroundColor: 'transparent !important',
            ...(isDarkMode
              ? {
                  borderColor: '#d1d1d1 !important'
                }
              : {})
          }}
          size="small"
          variant="outlined"
          onClick={handleDetailsClick}
        >
          Details
        </Button>
        <FavoriteButton ASIN={ASIN} finalPrice={finalPrice} />
        <Box display="inline-block">
          <DealCardActions {...props} />
        </Box>
      </CardActions>
    </>
  );
});

const DealCard = memo((props: DealCardProps) => {
  const { ASIN } = props;
  const { data: userFavorites } = useGetFavorites();
  const currentEventObj = getCurrentDealEventStrObj();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const showEventBorder = useMemo(() => {
    return (
      currentEventObj &&
      (props.listingMode || '')
        .toLowerCase()
        .includes(currentEventObj.value.toLowerCase())
    );
  }, [props.listingMode, currentEventObj]);

  const isFavorite = useMemo(() => {
    return ASIN && userFavorites?.includes(ASIN);
  }, [ASIN, userFavorites]);

  const cardClasses = useMemo(() => {
    return {
      ...classes.cardRoot,
      ...(props.isBestSeller
        ? {
            border: `2px solid ${
              isDarkMode ? 'transparent' : theme.palette.popularColor
            }`,
            boxSizing: 'border-box'
          }
        : {}),
      ...(showEventBorder && currentEventObj?.shouldShowDeals
        ? {
            border: `2px solid ${
              currentEventObj?.dealHighlightColor || colors.jdbPurple
            }`,
            boxSizing: 'border-box'
          }
        : {}),
      ...(isFavorite
        ? {
            border: `2px solid ${
              isDarkMode ? 'transparent' : theme.palette.favoriteColor
            }`,
            boxSizing: 'border-box'
          }
        : {})
    };
  }, [
    classes,
    props.isBestSeller,
    showEventBorder,
    isFavorite,
    currentEventObj
  ]);

  return (
    <Card
      key={ASIN}
      raised
      sx={{
        ...cardClasses,
        // width: '100%',         // Force full width
        // maxWidth: '400px',     // Example max width
        // minHeight: '200px',    // Prevent height fluctuations
        // height: 'auto',        // If height varies
        // border: '1px solid transparent', // Avoid dynamic borders
        boxSizing: 'border-box',
        transform: 'translateZ(0)', // Force GPU rendering
        willChange: 'transform' // Hint to browser
      }}
    >
      <DealInnerCard {...props} />
    </Card>
  );
});

export default DealCard;
