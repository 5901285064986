import React, { useState } from 'react';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Avatar,
  Typography
} from '@mui/material';
import { logPostHogEvent } from '@utils/index';
import trackUse from '@utils/trackUse';

const PercentageChip = ({
  percentOff,
  setPercentOff
}: {
  percentOff: number | null;
  setPercentOff: (value: number | null) => void;
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [tempPercentage, setTempPercentage] = useState<number | null>(
    percentOff
  );

  const applyPercentageSelection = () => {
    logPostHogEvent('filter-chip-percentage', {
      value: `${tempPercentage}`,
      type: 'ACTION'
    });
    trackUse({
      item: `filter-chip-percentage`,
      value: `${tempPercentage}`,
      type: 'ACTION'
    });

    setPercentOff(tempPercentage);
    setModalOpen(false);
  };

  const clearPercentageSelection = () => {
    setPercentOff(null);
    setTempPercentage(null);
    setModalOpen(false);
  };

  const renderModal = () => (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: 535 } }}
      maxWidth="xs"
      open={modalOpen}
      onClose={() => setModalOpen(false)}
    >
      <DialogTitle>Filter by Percentage Off</DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{
            margin: '8px 0'
          }}
        >
          <Typography>Select a percentage discount:</Typography>
          <RadioGroup
            value={tempPercentage}
            onChange={(event) => setTempPercentage(Number(event.target.value))}
          >
            {[30, 40, 50, 60, 70, 80].map((percentage) => (
              <FormControlLabel
                key={percentage}
                value={percentage}
                control={<Radio />}
                label={`${percentage}% off or more`}
              />
            ))}
          </RadioGroup>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px'
        }}
      >
        <Button onClick={clearPercentageSelection}>Clear</Button>
        <Button
          autoFocus
          onClick={applyPercentageSelection}
          color="primary"
          variant="outlined"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {renderModal()}
      <Chip
        label={
          percentOff !== null
            ? `Discount % at least ${percentOff}% off`
            : 'Discount %'
        }
        onClick={() => {
          setModalOpen(true);
        }}
        sx={{
          marginRight: '6px'
        }}
        variant={percentOff !== null ? 'filled' : 'outlined'}
        avatar={percentOff !== null ? <Avatar>%</Avatar> : undefined}
        onDelete={
          percentOff !== null
            ? () => {
                clearPercentageSelection();
              }
            : undefined
        }
      />
    </>
  );
};

export default PercentageChip;
