import React, { useState } from 'react';
import {
  Box,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Slider,
  Button,
  useTheme,
  Avatar,
  useMediaQuery,
  Typography
} from '@mui/material';
import { formatPrice, logPostHogEvent } from '@utils/index';
import trackUse from '@utils/trackUse';

const transformValueFunc = (value: number): number => {
  // Convert the linear slider value to the custom "log-like" scale.
  if (value <= 25) return value * 0.2; // $0 - $5
  if (value <= 50) return (value - 25) * 0.4 + 5; // $5 - $10
  if (value <= 75) return (value - 50) * 0.8 + 10; // $10 - $20
  if (value <= 100) return (value - 75) * 1.2 + 20; // $20 - $50
  if (value <= 150) return (value - 100) * 2.6 + 50; // $50 - $150
  if (value <= 175) return (value - 150) * 10 + 150; // $150 - $400
  return (value - 175) * 64 + 400; // $400 - $2000
};

const transformValue = (value: number): number => {
  return Math.round(transformValueFunc(value));
};

const inverseTransformValueFunc = (value: number): number => {
  // Convert the "log-like" scale value back to the linear slider value.
  if (value <= 5) return value / 0.2; // $0 - $5
  if (value <= 10) return (value - 5) / 0.4 + 25; // $5 - $10
  if (value <= 20) return (value - 10) / 0.8 + 50; // $10 - $20
  if (value <= 50) return (value - 20) / 1.2 + 75; // $20 - $50
  if (value <= 150) return (value - 50) / 2.6 + 100; // $50 - $150
  if (value <= 400) return (value - 150) / 10 + 150; // $150 - $400
  return (value - 400) / 64 + 175; // $400 - $2000
};

const inverseTransformValue = (value: number): number => {
  return Math.round(inverseTransformValueFunc(value));
};

const PriceChip = ({
  underPrice,
  overPrice,
  setUnderPrice,
  setOverPrice
}: {
  underPrice: number | null;
  overPrice: number | null;
  setUnderPrice: (value: number | null) => void;
  setOverPrice: (value: number | null) => void;
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalOpen, setModalOpen] = useState(false);
  const [tempPriceRange, setTempPriceRange] = useState<[number, number]>([
    inverseTransformValueFunc(underPrice ?? 0),
    inverseTransformValueFunc(overPrice ?? 2000)
  ]);

  const applyPriceRange = () => {
    logPostHogEvent('filter-chip-price', {
      value: `${overPrice || 0} - ${underPrice || 2000}`,
      type: 'ACTION'
    });
    trackUse({
      item: `filter-chip-price`,
      value: `${overPrice || 0} - ${underPrice || 2000}`,
      type: 'ACTION'
    });

    if (tempPriceRange[0] !== 0) {
      setOverPrice(transformValue(tempPriceRange[0]));
    }

    if (tempPriceRange[1] !== 200) {
      setUnderPrice(transformValue(tempPriceRange[1]));
    }

    setModalOpen(false);
  };

  const clearPriceRange = () => {
    setUnderPrice(null);
    setOverPrice(null);
    setTempPriceRange([0, inverseTransformValueFunc(2000)]);
    setModalOpen(false);
  };

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) {
      setTempPriceRange(newValue as [number, number]);
    }
  };

  const renderSlider = () => {
    return (
      <Slider
        value={tempPriceRange}
        onChange={handleSliderChange}
        valueLabelDisplay="auto"
        valueLabelFormat={(value) => `${formatPrice(transformValue(value))}`} // Custom tooltip text
        min={0}
        max={200} // Linear scale maximum
        step={1}
        sx={
          isMobile
            ? {
                '& .MuiSlider-markLabel': {
                  fontSize: '12px' // Adjust the size of the mark labels
                },
                '& .MuiSlider-valueLabel': {
                  fontSize: '14px' // Adjust the size of the value label (tooltip)
                }
              }
            : {}
        }
        marks={
          isMobile
            ? [
                { value: 0, label: '$0' },
                { value: 100, label: `${formatPrice(transformValue(100))}` },
                { value: 200, label: '$2,000+' }
              ]
            : [
                { value: 0, label: '$0' },
                { value: 25, label: `${formatPrice(transformValue(25))}` },
                { value: 50, label: `${formatPrice(transformValue(50))}` },
                { value: 75, label: `${formatPrice(transformValue(75))}` },
                { value: 100, label: `${formatPrice(transformValue(100))}` },
                { value: 125, label: `${formatPrice(transformValue(125))}` },
                { value: 150, label: `${formatPrice(transformValue(150))}` },
                { value: 200, label: '$2,000+' }
              ]
        }
      />
    );
  };

  const renderModal = () => (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: 435 } }}
      maxWidth="xs"
      open={modalOpen}
      onClose={() => setModalOpen(false)}
    >
      <DialogTitle>Filter by Price</DialogTitle>
      <DialogContent dividers>
        <Box>
          {[1, 2, 5, 10, 20, 50].map((price) => {
            return (
              <Chip
                key={price}
                sx={{
                  marginRight: '6px',
                  marginBottom: '6px'
                }}
                label={`Under ${formatPrice(price)}`}
                onClick={() => {
                  setTempPriceRange([0, inverseTransformValue(price)]);
                }}
              />
            );
          })}
        </Box>
        <Box
          margin={isMobile ? '24px 20px 16px 10px' : '24px 24px 16px 24px'}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 2
          }}
        >
          {renderSlider()}
          <Box>
            <Typography>
              {`Price Range: ${formatPrice(
                transformValue(tempPriceRange[0])
              )} — ${
                transformValue(tempPriceRange[1]) === 2000
                  ? '$2,000+'
                  : formatPrice(transformValue(tempPriceRange[1]))
              }`}
            </Typography>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px'
        }}
      >
        <Button onClick={clearPriceRange}>Clear</Button>
        <Button
          autoFocus
          onClick={applyPriceRange}
          color="primary"
          variant="outlined"
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {renderModal()}
      <Chip
        label={`Price${
          overPrice !== null || underPrice !== null
            ? ` ${
                overPrice && overPrice > 0 ? formatPrice(overPrice) : '$0'
              } - ${
                underPrice && underPrice < 2000
                  ? formatPrice(underPrice)
                  : 'any'
              }`
            : ''
        }`}
        onClick={() => {
          setModalOpen(true);
        }}
        sx={{
          marginRight: '6px'
        }}
        variant={underPrice || overPrice ? 'filled' : 'outlined'}
        avatar={underPrice || overPrice ? <Avatar>$</Avatar> : undefined}
        onDelete={
          underPrice || overPrice
            ? () => {
                clearPriceRange();
              }
            : undefined
        }
      />
    </>
  );
};

export default PriceChip;
