import { useEffect, useState, useCallback } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { SelectChangeEvent } from '@mui/material/Select';
import { useGetLatestDeals } from '@hooks/useDeals';
import useLocalStorage from '@hooks/useLocalStorage';
import trackUse from '@utils/trackUse';
import { useGetFavorites } from '@hooks/useFavorites';
import config from '@configFile';
import { logPostHogEvent, sortDeals } from '@utils/index';
import { useGetUserSettings } from '@hooks/useUserSettings';
import { useGetUserData } from '@hooks/useGetUserData';
import { DealPostType } from '@types';
import { dealsAction } from '../../actions';
import {
  filterLatestDeals,
  getNextDealASIN,
  getPreviousDealASIN
} from './utils';
import dealPageConfig from './defaultConfig';

export const useDealsState = (
  isEventDay: boolean | undefined,
  dealSummaryASIN: string | null
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { data: user } = useGetUserData();
  const { data: userSettings } = useGetUserSettings();
  const hasStickyFiltersEnabled =
    user && userSettings?.databaseStickyFilters === true;

  const { data: dealsData, isLoading: latestDealsLoading } =
    useGetLatestDeals();
  const latestDeals = dealsData?.latestDeals;
  const tagData = dealsData?.tag
    ? dealsData.tag
    : config.AFFILIATE_TAGS.DATABASE_CONTROL;
  const searchParams = new URLSearchParams(location?.search);
  const shouldShowV2Layout =
    searchParams?.get('layout') === 'v2' ||
    tagData === config.AFFILIATE_TAGS.DATABASE_TEST;
  const { data: favoriteItems = [] } = useGetFavorites();

  // State management for different filters and settings
  const [primeDayLocal, setPrimeDayLocal] = useState(isEventDay || false);
  const [primeDaySticky, setPrimeDaySticky] = useLocalStorage(
    'dashboard_prime_day',
    isEventDay || dealPageConfig.defaultPrimeDay
  );
  const primeDay = hasStickyFiltersEnabled ? primeDaySticky : primeDayLocal;
  const setPrimeDay = hasStickyFiltersEnabled
    ? setPrimeDaySticky
    : setPrimeDayLocal;

  const [trendingLocal, setTrendingLocal] = useState(false);
  const [trendingSticky, setTrendingSticky] = useLocalStorage(
    'dashboard_trending',
    dealPageConfig.defaultTrending
  );

  const trending = hasStickyFiltersEnabled ? trendingSticky : trendingLocal;
  const setTrending = hasStickyFiltersEnabled
    ? setTrendingSticky
    : setTrendingLocal;

  const [strategyFilterLocal, setStrategyFilterLocal] = useState('');
  const [strategyFilterSticky, setStrategyFilterSticky] = useLocalStorage(
    'dashboard_strategyFilter',
    dealPageConfig.strategyFilter
  );

  const strategyFilter = hasStickyFiltersEnabled
    ? strategyFilterSticky
    : strategyFilterLocal;
  const setStrategyFilter = hasStickyFiltersEnabled
    ? setStrategyFilterSticky
    : setStrategyFilterLocal;

  const [sortLocal, setSortLocal] = useState(dealPageConfig.defaultSort);
  const [sortSticky, setSortSticky] = useLocalStorage(
    'dashboard_sort',
    dealPageConfig.defaultSort
  );
  const sort = hasStickyFiltersEnabled ? sortSticky : sortLocal;
  const setSort = hasStickyFiltersEnabled ? setSortSticky : setSortLocal;

  const [onlySponsored, setOnlySponsored] = useState(
    dealPageConfig.defaultOnlySponsored
  );

  const [underPrice, setUnderPrice] = useState(
    dealPageConfig.defaultUnderPrice
  );

  const [percentOff, setPercentOff] = useState(
    dealPageConfig.defaultPercentOff
  );

  const [overPrice, setOverPrice] = useState(dealPageConfig.defaultOverPrice);

  const [onlyFavoritesLocal, setOnlyFavoritesLocal] = useState(
    dealPageConfig.defaultOnlyFavorites
  );
  const [onlyFavoritesSticky, setOnlyFavoritesSticky] = useLocalStorage(
    'dashboard_filter_only_favorites',
    dealPageConfig.defaultOnlyFavorites
  );
  const onlyFavorites = hasStickyFiltersEnabled
    ? onlyFavoritesSticky
    : onlyFavoritesLocal;
  const setOnlyFavorites = hasStickyFiltersEnabled
    ? setOnlyFavoritesSticky
    : setOnlyFavoritesLocal;

  const [onlyShowNewLocal, setOnlyShowNewLocal] = useState(
    dealPageConfig.defaultOnlyShowNew
  );
  const [onlyShowNewSticky, setOnlyShowNewSticky] = useLocalStorage(
    'dashboard_filter_only_show_new',
    dealPageConfig.defaultOnlyShowNew
  );
  const onlyShowNew = hasStickyFiltersEnabled
    ? onlyShowNewSticky
    : onlyShowNewLocal;
  const setOnlyShowNew = hasStickyFiltersEnabled
    ? setOnlyShowNewSticky
    : setOnlyShowNewLocal;

  const [onlyCouponsLocal, setOnlyCouponsLocal] = useState(
    dealPageConfig.defaultOnlyCoupons
  );
  const [onlyCouponsSticky, setOnlyCouponsSticky] = useLocalStorage(
    'dashboard_filter_only_coupons',
    dealPageConfig.defaultOnlyCoupons
  );
  const onlyCoupons = hasStickyFiltersEnabled
    ? onlyCouponsSticky
    : onlyCouponsLocal;
  const setOnlyCoupons = hasStickyFiltersEnabled
    ? setOnlyCouponsSticky
    : setOnlyCouponsLocal;

  const [onlySubscribeSaveLocal, setOnlySubscribeSaveLocal] = useState(
    dealPageConfig.defaultOnlySubscribeSave
  );
  const [onlySubscribeSaveSticky, setOnlySubscribeSaveSticky] = useLocalStorage(
    'dashboard_filter_only_subscribe_save',
    dealPageConfig.defaultOnlySubscribeSave
  );
  const onlySubscribeSave = hasStickyFiltersEnabled
    ? onlySubscribeSaveSticky
    : onlySubscribeSaveLocal;
  const setOnlySubscribeSave = hasStickyFiltersEnabled
    ? setOnlySubscribeSaveSticky
    : setOnlySubscribeSaveLocal;

  const [categoryFiltersLocal, setCategoryFiltersLocal] = useState([]);
  const [categoryFiltersSticky, setCategoryFiltersSticky] = useLocalStorage(
    'dashboard_filter_category_filters_chips',
    []
  );
  const categoryFilters = hasStickyFiltersEnabled
    ? categoryFiltersSticky
    : categoryFiltersLocal;
  const setCategoryFilters = hasStickyFiltersEnabled
    ? setCategoryFiltersSticky
    : setCategoryFiltersLocal;

  const [isFiltering, setIsFiltering] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [searchFilterDebounced, setSearchFilterDebounced] = useState('');

  const [amount, setAmount] = useState(dealPageConfig.loadPerScroll);

  let timeoutId: NodeJS.Timeout | null = null;

  const unfilteredDealsWithASIN = (latestDeals || []).filter((p) => !!p.ASIN);

  const dealsWithASIN = sortDeals(
    filterLatestDeals(
      {
        onlyFavorites,
        onlyShowNew,
        onlyCoupons,
        onlySubscribeSave,
        primeDay,
        categoryFilters,
        searchFilter: searchFilterDebounced,
        trending,
        onlySponsored,
        underPrice,
        overPrice,
        strategyFilter,
        percentOff
      },
      unfilteredDealsWithASIN,
      favoriteItems,
      searchFilterDebounced
    ),
    sort
  ) as DealPostType[];

  useEffect(() => {
    const navigateOnKey = (e: KeyboardEvent) => {
      let nextDealASIN = null;
      let trackUseName = 'next-deal-keyboard';
      if (e.key === 'ArrowRight') {
        nextDealASIN = getNextDealASIN(dealSummaryASIN, dealsWithASIN);
      } else if (e.key === 'ArrowLeft') {
        nextDealASIN = getPreviousDealASIN(dealSummaryASIN, dealsWithASIN);
        trackUseName = 'prev-deal-keyboard';
      }
      if (nextDealASIN) {
        trackUse({
          item: trackUseName,
          value: `/deal/${nextDealASIN}`,
          type: 'KEYBOARD'
        });

        logPostHogEvent(trackUseName, {
          value: `/deal/${nextDealASIN}`,
          type: 'KEYBOARD'
        });

        history.push(`/deal/${nextDealASIN}`);
        dispatch(dealsAction.getDealSummary(nextDealASIN));
      }
    };

    // listen to the keyboard
    window.addEventListener('keydown', navigateOnKey);

    return () => {
      window.removeEventListener('keydown', navigateOnKey);
    };
  }, [dealSummaryASIN, dealsWithASIN]);

  const toggleOnlyCoupons = () => {
    setOnlyCoupons(!onlyCoupons);
  };

  const resetAll = () => {
    setTrending(dealPageConfig.defaultTrending);
    setOnlyCoupons(dealPageConfig.defaultOnlyCoupons);
    setOnlySubscribeSave(dealPageConfig.defaultOnlySubscribeSave);
    setOnlyShowNew(dealPageConfig.defaultOnlyShowNew);
    setCategoryFilters([]);
    setOnlyFavorites(dealPageConfig.defaultOnlyFavorites);
    setSearchFilter('');
    setIsFiltering(false);
    setSearchFilterDebounced('');
    setPrimeDay(dealPageConfig.defaultPrimeDay);
    setStrategyFilter(dealPageConfig.strategyFilter);
    setUnderPrice(dealPageConfig.defaultUnderPrice);
    setOverPrice(dealPageConfig.defaultOverPrice);
    setPercentOff(dealPageConfig.defaultPercentOff);

    // scroll to top
    window?.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const loadMore = () => {
    // Clear the previous timeout if there was one
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      setAmount(amount + dealPageConfig.loadPerScroll);
    }, dealPageConfig.fakeLoadTimeout);
  };

  const handleSortChange = (event: SelectChangeEvent) => {
    setSort(event.target.value as string);
    trackUse({
      item: 'sort-deal-database-change',
      value: event.target.value as string,
      type: 'ACTION'
    });

    logPostHogEvent('sort-deal-database-change', {
      value: event.target.value as string,
      type: 'ACTION'
    });
  };

  const debounceUpdateFilteredItems = useCallback(
    _.debounce((value) => {
      setSearchFilterDebounced(value);
    }, 500),
    []
  );

  useEffect(() => {
    debounceUpdateFilteredItems(searchFilter);
    return () => {
      debounceUpdateFilteredItems.cancel();
    };
  }, [searchFilter, debounceUpdateFilteredItems]);

  return {
    primeDay,
    setPrimeDay,
    trending,
    setTrending,
    sort,
    setSort,
    onlyFavorites,
    setOnlyFavorites,
    onlyShowNew,
    setOnlyShowNew,
    onlyCoupons,
    setOnlyCoupons,
    onlySubscribeSave,
    setOnlySubscribeSave,
    categoryFilters,
    setCategoryFilters,
    isFiltering,
    setIsFiltering,
    searchFilter,
    setSearchFilter,
    searchFilterDebounced,
    setSearchFilterDebounced,
    strategyFilter,
    setStrategyFilter,
    amount,
    setAmount,
    dealsWithASIN,
    latestDealsLoading,
    shouldShowV2Layout,
    tagData,
    unfilteredDealsWithASIN,
    toggleOnlyCoupons,
    resetAll,
    loadMore,
    handleSortChange,
    onlySponsored,
    setOnlySponsored,
    underPrice,
    setUnderPrice,
    overPrice,
    setOverPrice,
    percentOff,
    setPercentOff,
    hasFilters: () => {
      const hasCategoryFilters = categoryFilters?.length !== 0;
      if (hasCategoryFilters) return true;
      if (searchFilterDebounced !== '') return true;
      if (strategyFilter !== '') return true;
      if (
        onlyFavorites ||
        onlyShowNew ||
        onlyCoupons ||
        trending ||
        primeDay ||
        onlySubscribeSave ||
        underPrice ||
        overPrice ||
        percentOff
      )
        return true;
      return false;
    }
  };
};
